import { useState, useContext, useEffect } from "react";
import AuthContext from "../../context/AuthContext";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Upload,
  message,
  Row,
  Col,
  InputNumber,
  Divider,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import "./AddExpense.css";
import PaymentMethodSelect from "./PaymentMethodSelect";
import { DEPARTMENT_CATEGORIES } from "../../utils/ExpenseUtils";

const { Option } = Select;

const AddExpense = () => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens.accessToken;

  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [projectAndDept, setProjectAndDept] = useState();
  const [isCategoryEnabled, setIsCategoryEnabled] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const {id} = useParams();

  useEffect(() => {
    const fetchProject = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BACKEND}/ideali/v1/projects/proj-dept/${id}`;

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        setProjectAndDept(data);
        form.setFieldsValue({
          project: data.name,
          department: data.department,
        });
        if (data.department !== "production") {
          form.setFieldsValue({
            category: DEPARTMENT_CATEGORIES[data.department][0],
          });
        }
        setCategories(DEPARTMENT_CATEGORIES[data.department] || []);
        setIsCategoryEnabled(data.department === "production");
      } catch (err) {
        console.log(err);
      }
    };

    fetchProject();
  }, [accessToken, id, form]);

  const handleFinish = async (values) => {
    setLoading(true);

    // Create a new FormData object
    const formData = new FormData();

    // Append non-file fields to the FormData
    formData.append("vendor", values.vendor.trim());
    formData.append("amount", parseFloat(values.amount));
    formData.append("description", values.description.trim());
    formData.append("date", new Date(values.date).toISOString());
    formData.append("projectId", id);
    formData.append("department", projectAndDept.department);
    formData.append("category", values.category);
    formData.append("paymentMethod", values.paymentMethod);
    if (values.note) formData.append("note", values.note.trim());

    // Append files to the FormData
    fileList.forEach((file) => {
      formData.append("receipts[]", file.originFileObj); // The actual file object
    });

    const headers = new Headers();
    headers.append("Authorization", "Bearer " + accessToken);

    let requestOptions = {
      method: "POST",
      headers: headers,
      body: formData, // Use the FormData as the body
      credentials: "include",
    };

    const url = `${process.env.REACT_APP_BACKEND}/ideali/v1/expense`;

    try {
      const res = await fetch(url, requestOptions);
      const data = await res.json();
      if (data.error) {
        message.error(data.error);
        setLoading(false);
      } else {
        message.success("Expense created successfully!");
        navigate("/expense-home");
        setLoading(false);
      }
    } catch (error) {
      message.error("An error occurred while creating the expense.");
      setLoading(false);
    }
  };

  const handleFileChange = (info) => {
    setFileList(info.fileList || []);
  };

  return (
    <div className="add-expense-container">
      <h2 className="text-center my-4">Create New Expense</h2>
      <Divider />
      <Form
        layout="vertical"
        onFinish={handleFinish}
        className="add-expense-form"
        form={form}
      >
        {/* First Row */}
        <Row gutter={16}>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Vendor"
              name="vendor"
              rules={[{ required: true, message: "Please enter a vendor!" }]}
            >
              <Input placeholder="e.g., Starbucks" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Date"
              name="date"
              rules={[{ required: true, message: "Please select a date!" }]}
            >
              <DatePicker className="w-100" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                { required: true, message: "Please enter a description!" },
              ]}
            >
              <Input placeholder="e.g., Coffee" />
            </Form.Item>
          </Col>
        </Row>

        {/* Second Row */}
        <Row gutter={16}>
          <Col xs={24} sm={8}>
            <Form.Item label="Project" name="project" required>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item label="Department" name="department" required>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Category"
              name="category"
              rules={[{ required: true, message: "Please select a category!" }]}
            >
              <Select
                placeholder="Select a category"
                disabled={!isCategoryEnabled}
              >
                {categories.map((category) => (
                  <Option key={category} value={category}>
                    {category}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Third Row */}
        <Row gutter={16}>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Amount"
              name="amount"
              rules={[
                { required: true, message: "Please enter the amount!" },
                {
                  pattern: /^-?\d+(\.\d{1,2})?$/,
                  message:
                    "Please enter a valid amount (e.g., -123.45 or 123.45)",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="e.g., -123.45"
                formatter={(value) =>
                  value
                    ? `$ ${value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    : "$"
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                step={0.01}
                min={-9999999} // Optional: Set a min limit if needed
                max={9999999} // Optional: Set a max limit if needed
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Payment Method"
              name="paymentMethod"
              rules={[
                { required: true, message: "Please select a payment method!" },
              ]}
            >
              <PaymentMethodSelect />
            </Form.Item>
          </Col>
        </Row>

        {/* Fourth Row */}
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item label="Note" name="note">
              <Input.TextArea placeholder="Add any additional notes (optional)" />
            </Form.Item>
          </Col>
        </Row>

        {/* Fifth Row */}
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item label="Receipts" name="receipts">
              <Upload
                listType="text"
                beforeUpload={() => false} // Prevent auto upload
                multiple
                fileList={fileList}
                onChange={handleFileChange}
              >
                <Button icon={<UploadOutlined />}>Upload Receipt(s)</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        {/* Buttons */}
        <div className="form-buttons">
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit Expense
          </Button>
          <Button
            type="default"
            onClick={() => navigate("/expense-home")}
            className="ml-2"
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddExpense;
