import { createContext, useState, useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export default AuthContext;

export function useAuthContext() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : null
  );
  const [alertMessage, setAlertMessage] = useState("");
  const [alertClassName, setAlertClassName] = useState("d-none");

  const AlertMessges = {
    "norows": "Not existing user",
    "password": "Invalid Credentials",
  }

  const isLoggedIn = !!authTokens

  let [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const signup = async (email, username, password, inviteCode, organization) => {
    // e.preventDefault();

    // build the request payload
    let payload = {
      email: email,
      username: username,
      password: password,
      inviteCode: inviteCode,
      organization: organization,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/users`;

    try {
      const res = await fetch(url, requestOptions);
      const data = await res.json();
      if (data.error) {
        setAlertClassName("alert-danger");
        // display error for duplicates
        // if (data.error.includes("no rows")) {
        //   setAlertMessage(AlertMessges["norows"]);
        // } else {
        //   setAlertMessage(AlertMessges["password"]);
        // }
      } else {
        setAuthTokens(data);
        setUser(data.user);
        localStorage.setItem("authTokens", JSON.stringify(data));
        localStorage.setItem("authUser", JSON.stringify(data.user));
        if (data.user.organization === "solidbox") {
          navigate("/tasks", { replace: true });
        } else if (data.user.organization === "ideali") {
          navigate("/expense-home", { replace: true });
        }
      }
    } catch (error) {
      setAlertClassName("alert-danger");
      setAlertMessage(error);
    }
  };

  const login = async (email, password) => {
    // e.preventDefault();

    // build the request payload
    let payload = {
      email: email,
      password: password,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/users/login`;

    try {
      const res = await fetch(url, requestOptions)
      const data = await res.json()
      if (data.error) {
        setAlertClassName("alert-danger");
        if (data.error.includes("no rows")) {
          setAlertMessage(AlertMessges["norows"]);
        } else {
          setAlertMessage(AlertMessges["password"]);
        }
      } else {
        setAuthTokens(data);
        setUser(data.user);
        localStorage.setItem("authTokens", JSON.stringify(data));
        localStorage.setItem("authUser", JSON.stringify(data.user));
        if (data.user.organization === "solidbox") {
          navigate("/tasks", { replace: true });
        } else {
          navigate("/expense-home", { replace: true });
        }
      }
    } catch(error) {
      setAlertClassName("alert-danger");
      setAlertMessage(error);
    }
  };

  const loginWithOTP = async (email, otp) => {
    // e.preventDefault();

    // build the request payload
    let payload = {
      email: email,
      otp: otp,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/otp/login`;

    try {
      const res = await fetch(url, requestOptions)
      const data = await res.json()
      if (data.error) {
        setAlertClassName("alert-danger");
        if (data.error.includes("no rows")) {
          setAlertMessage(AlertMessges["norows"]);
        } else {
          setAlertMessage(AlertMessges["password"]);
        }
      } else {
        setAuthTokens(data);
        setUser(data.user);
        localStorage.setItem("authTokens", JSON.stringify(data));
        localStorage.setItem("authUser", JSON.stringify(data.user));
        if (data.user.organization === "solidbox") {
          navigate("/tasks", { replace: true });
        } else {
          navigate("/expense-home", { replace: true });
        }
      }
    } catch(error) {
      setAlertClassName("alert-danger");
      setAlertMessage(error);
    }
  };

  const logout = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    localStorage.removeItem("authUser");
  };

  const updateToken = useCallback(async () => {
    // build the request payload
    let payload = {
      refreshToken: authTokens?.refreshToken,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_BACKEND}/v1/tokens/renew_access`;

    try {
      const res = await fetch(url, requestOptions)
      const data = await res.json()
      if (res.status === 200) {
        let updatedAuthTokens = JSON.parse(localStorage.getItem("authTokens"));
        updatedAuthTokens.accessToken = data.accessToken;
        updatedAuthTokens.accessTokenExpiresAt = data.accessTokenExpiresAt;
        setAuthTokens(updatedAuthTokens);
        localStorage.setItem("authTokens", JSON.stringify(updatedAuthTokens));
      } else {
        logout();
      }
    } catch(error) {
      logout();
    }

    if (loading) {
      setLoading(false);
    }
  }, [authTokens, loading]);

  let contextValue = {
    user: user,
    signup: signup,
    login: login,
    loginWithOTP: loginWithOTP,
    logout: logout,
    isLoggedIn: isLoggedIn,
    userRole: user ? user.role : null,
    userOrg: user ? user.organization : null,
    alertMessage: alertMessage,
    setAlertMessage: setAlertMessage,
    alertClassName: alertClassName,
    setAlertClassName: setAlertClassName,
    authTokens: authTokens,
  };

  useEffect(() => {
    if (loading) {
      updateToken();
    }

    let tenMinutes = 1000 * 60 * 14;

    let interval = setInterval(() => {
      if (authTokens) {
        updateToken();
      }
    }, tenMinutes);

    return () => clearInterval(interval);
  }, [authTokens, loading, updateToken]);

  return (
    <AuthContext.Provider value={contextValue}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
