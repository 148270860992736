import { Navigate, Outlet } from "react-router-dom";

const SolidboxRoutes = ({ isSolidbox }) => {
  if (!isSolidbox) {
    return <Navigate to="/notfound" />;
  }

  return <Outlet />;
};

export default SolidboxRoutes;
