import { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import {
  Button,
  Form,
  Input,
  Select,
  Divider,
  Spin,
  message,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Upload,
  List,
  Typography,
  Popconfirm,
} from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import PaymentMethodSelect from "./PaymentMethodSelect";
import { DEPARTMENT_CATEGORIES } from "../../utils/ExpenseUtils";

const { Option } = Select;

const ExpenseDetails = () => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens.accessToken;

  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [receiptsUpdated, setReceiptsUpdated] = useState(false);

  const [categories, setCategories] = useState([]);

  const [fileList, setFileList] = useState([]);
  const [receiptLinks, setReceiptLinks] = useState([]);
  const [projectId, setProjectId] = useState(null);

  const handleFileChange = (info) => {
    setFileList(info.fileList || []);
    setReceiptsUpdated(true);
  };

  // Handle file removal
  const handleRemoveLink = (link) => {
    setReceiptLinks((prevLinks) => prevLinks.filter((l) => l !== link));
    setReceiptsUpdated(true);
  };

  useEffect(() => {
    const fetchExpense = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BACKEND}/ideali/v1/expenses/${id}`;

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        form.setFieldsValue({
          vendor: data.vendor,
          amount: data.amount,
          description: data.description,
          project: data.project_name,
          department: data.department,
          category: data.category,
          paymentMethod: data.payment_method,
          note: data.note,
          date: dayjs(data.date)
        });
        setCategories(DEPARTMENT_CATEGORIES[data.department]);
        setReceiptLinks(data.receipt_links);
        setProjectId(data.project_id);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    fetchExpense();
  }, [accessToken, id, form, updated]);

  const handleSubmit = async (values) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("expense_id", id);
    formData.append("vendor", values.vendor.trim());
    formData.append("vendor", values.vendor.trim());
    formData.append("amount", parseFloat(values.amount));
    formData.append("description", values.description.trim());
    formData.append("date", new Date(values.date).toISOString());
    formData.append("project_id", projectId);
    formData.append("department", values.department);
    formData.append("category", values.category);
    formData.append("payment_method", values.paymentMethod);
    formData.append("receipts_updated", receiptsUpdated);
    if (values.note) formData.append("note", values.note.trim());

    // Append files to the FormData
    fileList.forEach((file) => {
      formData.append("receipts[]", file.originFileObj);
    });

    // update receipt links
    if (receiptLinks?.length > 0) {
      receiptLinks.forEach((link) => {
        formData.append("receipt_links", link);
      })
    }

    const headers = new Headers();
    headers.append("Authorization", "Bearer " + accessToken);

    const requestOptions = {
      method: "PATCH",
      headers: headers,
      body: formData,
      credentials: "include",
    };

    const url = `${process.env.REACT_APP_BACKEND}/ideali/v1/expenses/${id}`;

    try {
      let response = await fetch(url, requestOptions);
      if (response.ok) {
        message.success("Expense updated successfully");
        setUpdated((prev) => !prev);
      } else {
        message.error("Failed to update expense");
      }
    } catch (error) {
      console.error("Error updating expense", error);
      message.error("Error updating expense");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <Spin size="large" />
      ) : (
        <div className="m-2 px-2 w-75 mx-auto">
          <h2 className="text-center my-4">Update Expense</h2>
          <Divider />
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            className="add-expense-form"
          >
            {/* First Row */}
            <Row gutter={16}>
              <Col xs={24} sm={8}>
                <Form.Item
                  label="Vendor"
                  name="vendor"
                  rules={[
                    { required: true, message: "Please enter a vendor!" },
                  ]}
                >
                  <Input placeholder="e.g., Starbucks" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item
                  label="Date"
                  name="date"
                  rules={[{ required: true, message: "Please select a date!" }]}
                >
                  <DatePicker className="w-100" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    { required: true, message: "Please enter a description!" },
                  ]}
                >
                  <Input placeholder="e.g., Props" />
                </Form.Item>
              </Col>
            </Row>

            {/* Second Row */}
            <Row gutter={16}>
              <Col xs={24} sm={8}>
                <Form.Item label="Project" name="project" required>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item label="Department" name="department" required>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item
                  label="Category"
                  name="category"
                  rules={[
                    { required: true, message: "Please select a category!" },
                  ]}
                >
                  <Select
                    placeholder="Select a category"
                    disabled={categories.length < 2}
                  >
                    {categories.map((category) => (
                      <Option key={category} value={category}>
                        {category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {/* Third Row */}
            <Row gutter={16}>
              <Col xs={24} sm={8}>
                <Form.Item
                  label="Amount"
                  name="amount"
                  rules={[
                    { required: true, message: "Please enter the amount!" },
                    {
                      pattern: /^-?\d+(\.\d{1,2})?$/,
                      message:
                        "Please enter a valid amount (e.g., -123.45 or 123.45)",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="e.g., -123.45"
                    formatter={(value) =>
                      value
                        ? `$ ${value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        : "$"
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    step={0.01}
                    min={-9999999} // Optional: Set a min limit if needed
                    max={9999999} // Optional: Set a max limit if needed
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item
                  label="Payment Method"
                  name="paymentMethod"
                  rules={[
                    {
                      required: true,
                      message: "Please select a payment method!",
                    },
                  ]}
                >
                  <PaymentMethodSelect />
                </Form.Item>
              </Col>
            </Row>

            {/* Fourth Row */}
            <Row gutter={16}>
              <Col xs={24}>
                <Form.Item label="Note" name="note">
                  <Input.TextArea placeholder="Add any additional notes (optional)" />
                </Form.Item>
              </Col>
            </Row>

            {/* Fifth Row */}
            <Row gutter={16}>
              <Col xs={24}>
                <Form.Item label="Receipts" name="receiptLinks">
                  {/* Display existing links */}
                  <List
                    size="small"
                    bordered
                    dataSource={receiptLinks ?? []}
                    renderItem={(link) => (
                      <List.Item
                        actions={[
                          <Popconfirm
                            title="Are you sure you want to delete this receipt?"
                            onConfirm={() => handleRemoveLink(link)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <DeleteOutlined style={{ color: "red" }} />
                          </Popconfirm>,
                        ]}
                      >
                        <Typography.Link href={link} target="_blank">
                          {link}
                        </Typography.Link>
                      </List.Item>
                    )}
                    locale={{
                      emptyText: "No receipts found.",
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item name="receipts">
                  <Upload
                    listType="text"
                    beforeUpload={() => false} // Prevent auto upload
                    multiple
                    fileList={fileList}
                    onChange={handleFileChange}
                  >
                    <Button icon={<UploadOutlined />}>Upload Receipt(s)</Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>

            {/* Buttons */}
            <div className="form-buttons">
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={loading}
              >
                Update
              </Button>
              <Button
                type="default"
                size="large"
                onClick={() => navigate("/expense-list")}
                className="ml-2"
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
}

export default ExpenseDetails;
