import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoutes from "./utils/PrivateRoutes";
import { AuthProvider } from "./context/AuthContext";
import Home from './components/Home/Home.jsx'
import EditInventory from "./components/Inventory/EditInventory";
import ManageInventory from "./components/Inventory/ManageInventory";
import SearchInventory from "./components/Inventory/SearchInventory";
import Drivers from './components/Drivers/Drivers.jsx'
import UsersList from './components/Users/UsersList'
import { Layout } from "antd";
import Locations from "./components/Locations/Locations";
import AddLocation from "./components/Locations/AddLocation";
import AddInventory from "./components/Inventory/AddInventory";
import Signup from "./components/Users/Signup";
import NotFoundPage from "./components/NotFoundPage";
import ViewLocation from "./components/Locations/ViewLocation";
import AdminRoutes from "./utils/AdminRoutes";
import IdealiAdminRoutes from "./utils/ExpenseAdminRoutes.js";
import Profile from "./components/Users/Profile";
import AdminEditUser from "./components/Users/AdminEditUser";
import InventorySummary from "./components/Inventory/InventorySummary.jsx";
import InventoryCityList from "./components/Inventory/InventoryCityList";
import Leads from "./components/Leads/Leads";
import AddLead from "./components/Leads/AddLead";
import LeadDetails from "./components/Leads/LeadDetails";
import LeadsFromHubspot from "./components/Leads/LeadsFromHubspot";
import TodosList from "./components/Todos/TodosList";
import DeliveryDetails from "./components/Home/DeliveryDetails.jsx";
import ManagePrices from "./components/Inventory/ManagePrices.jsx";
import Login from "./components/Users/Login.jsx";
import AddDriver from "./components/Drivers/AddDriver.jsx";
import DriverDetails from "./components/Drivers/DriverDetails.jsx";
import SolidboxLayout from "./components/SolidboxLayout.jsx";
import LoginIdeali from "./components/Users/LoginIdeali.jsx";
import ExpenseHome from "./components/Expenses/ExpenseHome.jsx";
import AddExpense from "./components/Expenses/AddExpense.jsx";
import ExpenseList from "./components/Expenses/ExpenseList.jsx";
import ExpenseDetails from "./components/Expenses/ExpenseDetails.jsx";
import LoginIdealiAdmin from "./components/Users/LoginIdealiAdmin.jsx";
import { Helmet } from "react-helmet";
import SolidboxRoutes from "./utils/SolidboxRoutes.js";
import ExpenseRoutes from "./utils/ExpenseRoutes.js";
import ExpenseLayout from "./components/ExpenseLayout.jsx";
import ExpensePrivateRoutes from "./utils/ExpensePrivateRoutes.js";
import ProjectList from "./components/Expenses/ProjectList.jsx";

function LayoutWrapper() {
  const domain = window.location.hostname;
  const isExpense = domain === process.env.REACT_APP_EXPENSE_DOMAIN;

  const LayoutComponent = isExpense ? ExpenseLayout : SolidboxLayout;
  const isSolidbox = !isExpense

  // Conditionally set the component for the home route
  const HomeComponent = isExpense ? LoginIdeali : Home;

  return (
    <LayoutComponent>
      <Helmet>
        <title>{isExpense ? "Expenses Portal" : "Portal"}</title>
        <link
          rel="icon"
          type="image/x-icon"
          href={
            isExpense
              ? "/images/ideali-favicon.png"
              : "/images/sbc-favicon.png"
          }
        />
      </Helmet>

      <Routes>
        {/* shared route for home */}
        <Route path="/" element={<HomeComponent />} />

        {/* solidbox */}
        <Route element={<SolidboxRoutes isSolidbox={isSolidbox} />}>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup isSolidbox={isSolidbox} />} />
          <Route path="/inventory" element={<InventorySummary />} />
          <Route path="/inventory/search" element={<SearchInventory />} />
          <Route path="/delivery-details" element={<DeliveryDetails />} />

          <Route element={<PrivateRoutes/>}>
            <Route
              path="/inventory/city/:city"
              element={<InventoryCityList />}
            />
            <Route path="/locations" element={<Locations />} />
            <Route path="/locations/:id" element={<ViewLocation />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/leads" element={<Leads />} />
            <Route path="/leads/:id" element={<LeadDetails />} />
            <Route path="/add-lead" element={<AddLead />} />
            <Route path="/tasks" element={<TodosList />} />
            <Route path="/manage-prices" element={<ManagePrices />} />
          </Route>

          <Route element={<AdminRoutes />}>
            <Route path="/add-inventory" element={<AddInventory />} />
            <Route path="/inventory/:id" element={<EditInventory />} />
            <Route path="/manage-inventory" element={<ManageInventory />} />
            <Route path="/drivers" element={<Drivers />} />
            <Route path="/driver/:id" element={<DriverDetails />} />
            <Route path="/add-driver" element={<AddDriver />} />
            <Route path="/admin/users" element={<UsersList />} />
            <Route path="/admin/users/:id" element={<AdminEditUser />} />
            <Route path="/add-location" element={<AddLocation />} />·{" "}
            <Route path="/admin/leads-hubspot" element={<LeadsFromHubspot />} />
          </Route>
        </Route>

        {/* ideali */}
        <Route element={<ExpenseRoutes isExpense={isExpense} />}>
          <Route path="/expense-login" element={<LoginIdeali />} />
          <Route path="/expense-login-admin" element={<LoginIdealiAdmin />} />
          <Route
            path="/expense-signup"
            element={<Signup isSolidbox={isSolidbox} />}
          />
          <Route element={<ExpensePrivateRoutes />}>
            <Route path="/expense-home" element={<ExpenseHome />} />
            <Route path="/expense/projects" element={<ProjectList addExpense={false}/>} />
            <Route path="/expense/projects/:id" element={<ExpenseList />} />
            <Route path="/add-expense/projects" element={<ProjectList addExpense={true}/>} />
            <Route path="/add-expense/projects/:id" element={<AddExpense />} />
            <Route path="/expense/:id" element={<ExpenseDetails />} />
          </Route>
          <Route element={<IdealiAdminRoutes />}></Route>
        </Route>

        <Route path="*" element={<NotFoundPage />} />

      </Routes>
    </LayoutComponent>
  );
}

function App() {
  return (
    <Router>
    <div>
      <Layout style={{ height: "100vh" }}>
          <AuthProvider>
            <LayoutWrapper />
          </AuthProvider>
      </Layout>
    </div>
    </Router>
  );
}

export default App;
