import React from "react";
import { Divider } from 'antd'

function NotFoundPage() {
  return (
    <div className="text-center">
      <h2 className='my-4'>Thank You</h2>
      <h6>Please visit us at another time.</h6>
      <Divider />
    </div>
  );
}

export default NotFoundPage;
