import { Layout } from "antd";
import IdealiHeaderMenu from "./Navigation/ExpenseHeaderMenu";

const ExpenseLayout = ({ children }) => {
  const { Header, Content } = Layout;
  const headerStyle = {
    color: "#fff",
    maxHeight: "8vh",
    display: "flex",
    backgroundColor: "#004b8d", // A different color for Ideali
  };
  const contentStyle = {
    height: "92vh",
    overflow: "auto",
  };

  return (
    <Layout style={{ height: "100vh" }}>
      <Header style={headerStyle}>
        <IdealiHeaderMenu />
      </Header>
      <Content style={contentStyle}>{children}</Content>
    </Layout>
  );
};

export default ExpenseLayout;
