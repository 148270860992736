// PaymentMethodSelect.js
import React from "react";
import { Select } from "antd";
import { PAYMENT_METHODS } from "../../utils/ExpenseUtils";

const { Option } = Select;

const PaymentMethodSelect = ({ value, onChange }) => {
  return (
    <Select
      placeholder="Select a payment method"
      value={value}
      onChange={onChange}
    >
      {PAYMENT_METHODS.map(({ value, label }) => (
        <Option key={value} value={value}>
          {label}
        </Option>
      ))}
    </Select>
  );
};

export default PaymentMethodSelect;
