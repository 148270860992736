import { Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

const PrivateRoutes = ( ) => {
  let { isLoggedIn, userOrg } = useContext(AuthContext);

  if ( userOrg !== "solidbox" ) {
    return <Navigate to="/notfound" />;
  }

  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
