import { Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

const AdminRoutes = () => {
  let { isLoggedIn, userRole, userOrg } = useContext(AuthContext);

  if (userOrg !== "solidbox" || userRole === "user") {
    // Redirect to NotFound if user is from a different organization
    return <Navigate to="/notfound" />;
  }

  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default AdminRoutes;
