import { Divider } from "antd";
import PasswordLogin from "./PasswordLogin";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import AuthContext from "../../context/AuthContext";

const LoginIdealiAdmin = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/expense-home");
    }
  }, [isLoggedIn, navigate]);

  return (
    <div className="m-2">
      <h2 className="text-center my-4">Log In</h2>
      <Divider />
      <PasswordLogin />
    </div>
  );
};

export default LoginIdealiAdmin;
