import { useNavigate } from "react-router-dom";
import { PlusOutlined, BarChartOutlined } from "@ant-design/icons";
import "./ExpenseHome.css";

const ExpenseHome = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path, { replace: true });
  };

  return (
    <>
      <div className="expenses-home-container">
        <h2 className="m-5 roboto-font text-center">
          Welcome to Ideali Projects Expenses System
        </h2>
        <div className="row justify-content-center align-items-center mx-0">
          <div className="col-lg-3 col-md-5 col-sm-8 mb-3">
            <div
              className="interactive-card square-card border bg-light text-center"
              onClick={() => handleNavigation("/add-expense/projects")}
            >
              <PlusOutlined className="icon mb-3" />
              <h5>Add New Expense</h5>
            </div>
          </div>
          <div className="col-lg-3 col-md-5 col-sm-8 mb-3">
            <div
              className="interactive-card square-card border bg-light text-center"
              onClick={() => handleNavigation("/expense/projects")}
            >
              <BarChartOutlined className="icon mb-3" />
              <h5>Check Records</h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpenseHome;
