import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { Button, Modal, Tooltip } from "antd";
import Logo from "./../../images/ideali-logo-banner.png";
import {
  LogoutOutlined,
  FileSearchOutlined,
  InfoOutlined,
} from "@ant-design/icons";

const ExpenseHeaderMenu = () => {
  const { isLoggedIn, logout, user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    logout();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div className="w-100 d-flex justify-content-between align-items-center">
      <div>
        <Link to="/" style={{ textDecoration: "none" }}>
          <img
            src={Logo}
            alt="ideali-logo"
            style={{ maxHeight: "5vh", maxWidth: "20vw" }}
          />
        </Link>
      </div>
      <div>
        {isLoggedIn ? (
          <div className="d-flex gap-3 align-items-center">
            <Tooltip title="Expenses Records">
              <Button
                shape="circle"
                icon={<FileSearchOutlined />}
                onClick={() => navigate("/expense/projects")}
              />
            </Tooltip>
            <Tooltip title={`Hi ${user.username}! Any questions? Please contact jameshong177@gmail.com`}>
              <Button shape="circle" icon={<InfoOutlined />} />
            </Tooltip>
            <Tooltip title="log out">
              <Button
                shape="circle"
                icon={<LogoutOutlined />}
                onClick={showModal}
              />
            </Tooltip>
            <Modal
              title="Are you sure you want to logout?"
              open={open}
              onOk={handleOk}
              onCancel={handleCancel}
              centered={true}
              closeIcon={false}
              okText="Log Out"
              destroyOnClose={true}
            ></Modal>
          </div>
        ) : (
          <div className="d-flex gap-3 align-items-center">
            <Tooltip title="Any questions? Please contact jameshong177@gmail.com">
              <Button shape="circle" icon={<InfoOutlined />} />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpenseHeaderMenu;
