import { useEffect, useState, useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Divider } from "antd";
import { PlusOutlined, DatabaseOutlined } from "@ant-design/icons";
import "./ProjectList.css";

const ProjectList = ( { addExpense } ) => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens.accessToken;
  const [projects, setProjects] = useState([]);

  const navigate = useNavigate();

  // Fetch projects data on component mount
  useEffect(() => {
    const fetchProjects = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + accessToken);

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BACKEND}/ideali/v1/projects`;

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        setProjects(data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchProjects();
  }, [accessToken]);

  const handleNavigation = (path) => {
    navigate(path, { replace: true });
  };


  return (
    <div className="m-2 mt-5">
      <h2 className="mb-4 text-center">Your Projects</h2>
      <Divider />
      <div className="row justify-content-center mx-0">
        {projects.map((project) => (
          <div className="col-lg-3 col-md-5 col-sm-8 m-3" key={project.id}>
            <div
              className="interactive-card square-card border bg-light text-center"
              onClick={() =>
                handleNavigation(
                  addExpense
                    ? `/add-expense/projects/${project.id}`
                    : `/expense/projects/${project.id}`
                )
              }
            >
              {addExpense ? (
                <PlusOutlined className="icon mb-3" />
              ) : (
                <DatabaseOutlined className="icon mb-3" />
              )}
              <h5 className="px-2">{project.project_name}</h5>
              <span>
                <em>
                  <strong>
                    "{project.project_acronym}"
                  </strong>
                </em>
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectList;
