export const DEPARTMENT_CATEGORIES = {
  production: ["catering", "crafty", "equipment", "location", "other"],
  art: ["art"],
  costume: ["costume"],
  other: ["other"],
};

export const PAYMENT_METHODS = [
  { value: "mastercard-4573", label: <>Mastercard <strong>4573</strong></> },
  { value: "mastercard-8469", label: <>Mastercard <strong>8469</strong></> },
  { value: "visa-4971", label: <>Visa <strong>4971</strong></> },
  { value: "visa-4963", label: <>Visa <strong>4963</strong></> },
  { value: "amex-1016", label: <>Amex <strong>1016</strong></> },
  { value: "other", label: "Other" },
];
