import { Navigate, Outlet } from "react-router-dom";

const ExpenseRoutes = ({ isExpense }) => {
  if (!isExpense) {
    return <Navigate to="/notfound" />;
  }

  return <Outlet />;
};

export default ExpenseRoutes;
