import { Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

const ExpensePrivateRoutes = ( ) => {
  let { isLoggedIn, userOrg } = useContext(AuthContext);

  if ( userOrg !== "ideali") {
    return <Navigate to="/notfound" />;
  }

  return isLoggedIn ? <Outlet /> : <Navigate to="/expense-login" />;
};

export default ExpensePrivateRoutes;
