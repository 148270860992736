import {
  Divider,
  Table,
  Button,
  Input,
  Tooltip,
  Select,
  Dropdown,
  Modal,
  message,
  Collapse,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { SearchOutlined, MoreOutlined, PlusOutlined, DownloadOutlined, FolderOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv"; // Import react-csv
import { DEPARTMENT_CATEGORIES, PAYMENT_METHODS } from "../../utils/ExpenseUtils";
import "./ExpenseList.css";

const { Option } = Select;

const ExpenseList = () => {
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [selectedUserId, setSelectedUserId] = useState();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

  const [folderLinks, setFolderLinks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isCategoryEnabled, setIsCategoryEnabled] = useState(false);

  const [userOptions, setUserOptions] = useState([]);
  const [totalByCategory, SetTotalByCategory] = useState(null)
  const [total, setTotal] = useState(null)

  const [recordDeleted, setRecordDeleted] = useState(false);

  const { authTokens, userRole } = useContext(AuthContext);
  const accessToken = authTokens.accessToken;

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getUserOptions = ( users ) => {
      let checkedUsers = new Set();
      let userOptions = [];
      users.forEach((user) => {
        if (!checkedUsers.has(user.user_id)) {
          checkedUsers.add(user.user_id);
          userOptions.push({
            label: user.username,
            value: user.user_id,
          });
        }
      });
      setUserOptions(userOptions);
      if (users.length > 0) {
        if (users[0].department === "production") {
          setIsCategoryEnabled(true);
          setCategories(DEPARTMENT_CATEGORIES.production);
          setFolderLinks(users[0].folder_links);
        }
      }
    }

    const fetchDepartmentUsers = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${accessToken}`);

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BACKEND}/ideali/v1/projects/department-users?project_id=${id}`;

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        getUserOptions(data);
      } catch (err) {
        console.error("Failed to fetch department users:", err);
      }
    }

    fetchDepartmentUsers();
  }, [accessToken, id])

  useEffect(() => {
    const fetchExpenses = async () => {
      setLoading(true);

      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${accessToken}`);

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      const queryParams = new URLSearchParams({
        project_id: id,
        search_value: searchValue,
        category: selectedCategory ? selectedCategory : "",
        payment_method: selectedPaymentMethod ? selectedPaymentMethod : "",
      });

      let url = `${
        process.env.REACT_APP_BACKEND
      }/ideali/v1/expenses/project-department?${queryParams.toString()}`;
      if (selectedUserId) {
        url += `&filter_user_id=${selectedUserId}`;
      }

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        setExpenses(data);
        setLoading(false);
      } catch (err) {
        console.error("Failed to fetch expenses:", err);
        setLoading(false);
      }
    };

    fetchExpenses();
  }, [
    accessToken,
    userRole,
    searchValue,
    selectedUserId,
    selectedCategory,
    selectedPaymentMethod,
    recordDeleted,
    id
  ]);

  useEffect(() => {
    const fetchSummary = async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${accessToken}`);

      const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BACKEND}/ideali/v1/expenses/project-summary/${id}`;

      try {
        let response = await fetch(url, requestOptions);
        let data = await response.json();
        setTotal(data.total_amount)
        SetTotalByCategory(data.amount_by_category)
        setLoading(false);
      } catch (err) {
        console.error("Failed to fetch expense summary:", err);
        setLoading(false);
      }
    }

    fetchSummary();
  }, [accessToken, id])

  const handleActionClick = (action, record) => {
    switch (action) {
      case "edit":
        navigate(`/expense/${record.expense_id}`);
        break;
      case "delete":
        Modal.confirm({
          title: "Are you sure you want to delete this record?",
          content: "This action cannot be undone.",
          onOk: () => {
            handleDelete(record.expense_id);
          },
        });
        break;
      default:
        break;
    }
  };

  const menuItems = (record) => [
    { key: "edit", label: "Edit" },
    { key: "delete", label: "Delete" },
  ];

  const getMenu = (record) => ({
    items: menuItems(record),
    onClick: ({ key }) => handleActionClick(key, record),
  });

  const handleDelete = (id) => {
    const deleteExpense = async (id) => {
      setLoading(true);
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${accessToken}`);

      let requestOptions = {
        method: "DELETE",
        headers: headers,
        credentials: "include",
      };

      const url = `${process.env.REACT_APP_BACKEND}/ideali/v1/expenses/${id}`;

      try {
        let res = await fetch(url, requestOptions);
        if (!res.ok) {
          throw new Error(`Failed to delete expense record: ${res.status}`);
        }
        message.success(`Expense record removed`);
      } catch (err) {
        console.error("Failed to delete expense record:", err);
        message.error("Failed to delete expense record");
      } finally {
        setLoading(false);
        setRecordDeleted((prev) => !prev);
      }
    };

    deleteExpense(id);
  };

  const columns = [
    {
      title: "No.",
      key: "record_number",
      align: "center",
      width: 60,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: 120,
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      render: (value) =>
        new Date(value).toLocaleDateString("en-US", {
          hour12: false,
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      ellipsis: true,
      width: 150,
      render: (value, record) => (
        <Link
          to={`/expense/${record.expense_id}`}
          style={{ textDecoration: "none" }}
        >
          <span className="hover-bold">{value}</span>
        </Link>
      ),
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      align: "center",
      width: 100,
      ellipsis: true,
      sorter: (a, b) => a.vendor.localeCompare(b.vendor),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: 150,
      ellipsis: true,
      render: (value) => `$${value.toFixed(2)}`,
    },
    {
      title: "Project",
      key: "project",
      align: "center",
      ellipsis: true,
      width: 120,
      render: (record) => {
        const acronym = record.project_acronym;
        const fullName = record.project_name;
        return (
          <Tooltip title={fullName}>
            <span>{acronym}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      align: "center",
      width: 120,
      ellipsis: true,
      render: (value) => `${value.charAt(0).toUpperCase() + value.slice(1)}`,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      align: "center",
      width: 120,
      ellipsis: true,
      render: (value) => `${value.charAt(0).toUpperCase() + value.slice(1)}`,
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
      align: "center",
      width: 150,
      ellipsis: true,
      render: (value) => {
        const paymentMethod = PAYMENT_METHODS.find(
          (method) => method.value === value
        );
        return paymentMethod ? paymentMethod.label : "-";
      },
    },
    {
      title: "By",
      dataIndex: "username",
      key: "username",
      align: "center",
      width: 120,
      ellipsis: true,
    },
    {
      title: "Notes",
      dataIndex: "note",
      key: "note",
      align: "left",
      ellipsis: true,
      width: 150,
      render: (note) => (note ? <Tooltip title={note}>{note}</Tooltip> : "-"),
    },
    {
      title: "Receipts",
      dataIndex: "receipt_links",
      key: "receipt_links",
      align: "left",
      width: 150,
      render: (receipt_links, record) => {
        if (receipt_links && receipt_links.length > 0) {
          return (
            <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
              {receipt_links.map((link, index) => (
                <li key={index}>
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    Receipt {index + 1}
                  </a>
                </li>
              ))}
            </ul>
          );
        }
        return (
          <Button
            type="primary"
            onClick={() => navigate(`/expense/${record.expense_id}`)}
          >
            Upload
          </Button>
        );
      },
    },
    {
      title: "More",
      key: "actions",
      align: "center",
      width: 100,
      fixed: "right",
      render: (record) => (
        <Dropdown menu={getMenu(record)} trigger={["click"]}>
          <Button
            type="text"
            icon={<MoreOutlined />}
            onClick={(e) => e.preventDefault()}
          />
        </Dropdown>
      ),
    },
  ];

  const formattedExpenses = expenses.length !== 0 ? expenses.map((expense) => {
    // Format the date field to MM/DD/YYYY
    const formattedDate = new Date(expense.date).toLocaleDateString("en-US", {
      hour12: false,
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    return {
      ...expense,
      date: formattedDate,
    };
  }) : expenses;

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const collapseItems = [
    {
      key: "1",
      label: (
        <span>
          <strong>Filters</strong>
        </span>
      ),
      children: (
        <div className="d-flex flex-wrap gap-3 align-items-center">
          <Select
            style={{ width: "200px" }}
            placeholder="user"
            allowClear
            options={userOptions}
            filterOption={filterOption}
            onChange={(value) => setSelectedUserId(value)}
          />
          {isCategoryEnabled && (
            <Select
              style={{ width: "200px" }}
              placeholder="category"
              allowClear
              filterOption={filterOption}
              onChange={(value) => setSelectedCategory(value)}
            >
              {categories.map((category) => (
                <Option key={category} value={category}>
                  {category}
                </Option>
              ))}
            </Select>
          )}
          <Select
            style={{ width: "200px" }}
            placeholder="payment method"
            allowClear
            onChange={(value) => setSelectedPaymentMethod(value)}
          >
            {PAYMENT_METHODS.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
          <Input
            prefix={<SearchOutlined />}
            style={{ width: "200px" }}
            placeholder="Search description, vendor, or note"
            allowClear
            onChange={(event) => setSearchValue(event.target.value)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="p-3 m-auto">
      <h2 className="my-4 text-center">Expenses</h2>
      <Divider />
      <div className="d-flex flex-wrap gap-3">
        <Button type="primary" icon={<PlusOutlined />}>
          <Link
            to={`/add-expense/projects/${id}`}
            style={{ textDecoration: "none" }}
          >
            Add a new expense
          </Link>
        </Button>
        <Button type="default" icon={<FolderOutlined />}>
          <Link
            to={folderLinks.length > 0 && folderLinks[0]}
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Check Receipts Folder
          </Link>
        </Button>
        <CSVLink
          data={formattedExpenses}
          headers={[
            { label: "Expense ID", key: "id" },
            { label: "Date", key: "date" },
            { label: "Description", key: "description" },
            { label: "Vendor", key: "vendor" },
            { label: "Amount", key: "amount" },
            { label: "Project", key: "project_name" },
            { label: "Department", key: "department" },
            { label: "Category", key: "category" },
            { label: "Payment Method", key: "payment_method" },
            { label: "Submitted By", key: "username" },
            { label: "Notes", key: "note" },
            { label: "Receipt Links", key: "receipt_links" },
          ]}
          filename={`expenses_${new Date().toLocaleDateString()}.csv`}
        >
          <Button icon={<DownloadOutlined />}>Download CSV</Button>
        </CSVLink>
      </div>
      <Divider />
      <Collapse
        items={collapseItems}
        defaultActiveKey={[]}
        className="mb-4"
        breakPoint="xs"
      />
      {total && (
        <Collapse defaultActiveKey={[]} className="mb-4" breakPoint="xs">
          <Collapse.Panel header={<strong>Summary</strong>} key="1">
            <div className="d-flex flex-wrap gap-3">
              <span>
                <strong>Total: ${total.toFixed(2)}</strong>
              </span>
              {Object.entries(totalByCategory).map(([key, value]) => (
                <div key={key}>
                  <span>
                    <em>
                      {key.charAt(0).toUpperCase() + key.slice(1)}:
                      ${value.toFixed(2)}
                    </em>
                  </span>
                </div>
              ))}
            </div>
          </Collapse.Panel>
        </Collapse>
      )}
      <Divider />
      <Table
        columns={columns}
        dataSource={expenses}
        scroll={{ x: 1200, y: "100%" }}
        rowKey="expense_id"
        loading={loading}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
        }}
      />
    </div>
  );
};

export default ExpenseList;
