// SolidboxLayout.jsx
import { Layout } from "antd";
import { useState, useEffect } from "react";
import NavMenu from "./Navigation/NavMenu";
import HeaderMenu from "./Navigation/HeaderMenu";

const SolidboxLayout = ({ children }) => {
  const { Header, Sider, Content } = Layout;
  const headerStyle = {
    color: "#fff",
    maxHeight: "8vh",
    display: "flex",
    backgroundColor: "#002140",
  };
  const contentStyle = {
    height: "92vh",
    overflow: "auto",
  };
  const siderStyle = {
    textAlign: "center",
    lineHeight: "120px",
    color: "#fff",
  };

  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    // Set the initial state of collapsed based on screen width
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout style={{ height: "100vh" }}>
      <Header style={headerStyle}>
        <HeaderMenu />
      </Header>
      <Layout hasSider>
        <Sider style={siderStyle} collapsible collapsed={collapsed} theme="light">
          <NavMenu />
        </Sider>
        <Content style={contentStyle}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default SolidboxLayout;
